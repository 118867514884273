import MessageToAppHandler from './message_to_app_handler';

export const messageToAppKeys = {
  action: 'action',
  params: 'params',
  title: 'title',
  crossPlatformNavigation: 'cross_platform_navigation',
  nativeBackPressed: 'native_back_pressed',
  isBackAvailable: 'is_back_available',
  dismiss: 'dismiss',
  onPay: 'on_pay',
  initialLoaded: 'initial_loaded',
  initialOptionPicked: 'initial_option_picked',
  initiateSession: 'initiate_session',
  paymentMethodAdded: 'payment_method_added',
  skipAndContinue: 'skip_and_continue'
};

export default MessageToAppHandler;
