import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { purchaseErrorText, paymentSourcesTheme, appThemes } from '../../config/const';
import classes from './classes.module.scss';
import CreditCardImg from '../../blue_snap/credit_card_img';
import ExpandedText from '../../helpers/expanded_text';
import {
  formatCardName, hideEmailAdress, isPaygAndNotEligibleToPayAsYouGo, getAppTheme, getIcon
} from '../../config/utils';

const payPalImg = require('../img/paypal_icon.svg');
const paymentSourceError = require('./img/payment_source_error.svg');

const requireContext = require.context('./img', false, /\.svg$/);

const paymentKind = {
  payPal: 'pp',
  creditCard: 'cc'
};

const renderErrorText = ({ errorText }) => {
  if (!errorText) return null;
  return (
    <div className={ classes.errorText }>
      { errorText }
    </div>
  );
};

const renderDefaultText = ({ isDefault, t }) => {
  if (!isDefault) return null;
  return (
    <div className={ classes.defaultText }>
      { t('purchase.default') }
    </div>
  );
};

const renderAdditionalTextIfPaypalBillingAgreementEmail = ({
  paypalBillingAgreementEmail, theme
}) => {
  if (paypalBillingAgreementEmail) {
    if (theme === paymentSourcesTheme.wallet) return null;
    return (
      <ExpandedText
        customClasses={ { text: classes.defaultText } }
        ellipsis
        ellipsisTextStyle={ { color: '#7D7D7D', fontWeight: '500', wordBreak: 'break-all' } }
      >
        { hideEmailAdress(paypalBillingAgreementEmail) }
      </ExpandedText>
    );
  }
  return null;
};

const renderAdditionalText = ({
  errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg
}) => {
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo }) && !errorText) return renderErrorText({ errorText: t('purchase.this_card_cannot_be_used_for_zen_mode') });
  if (errorText) return renderErrorText({ errorText });
  if (isDefault && (theme === paymentSourcesTheme.wallet || changingSource)) {
    return renderDefaultText({ isDefault, t });
  }
  return renderAdditionalTextIfPaypalBillingAgreementEmail({ paypalBillingAgreementEmail, theme });
};

const renderErrorImg = ({ errorText }) => {
  if (!errorText) return null;
  return (
    <div className={ classes.sourceErrorContainer }>
      <img src={ paymentSourceError } className={ classes.errorImg } alt="" />
    </div>
  );
};

const renderArrowImg = ({ changingSource }) => {
  if (changingSource) return null;

  const arrowIcon = getIcon({
    iconName: 'payment_arrow', requireContext, forAppContext: true
  });

  return <img src={ arrowIcon } alt="" className={ classes.paymentArrow } />;
};

const borderColor = ({ isDefault, changingSource, theme }) => {
  if (isDefault && (changingSource
    || theme === paymentSourcesTheme.wallet)) return { border: '1px solid #7FD14A' };
  return { border: '0.5px solid var(--app-border-primary-color)' };
};

const backgroundColor = ({
  eligibleToPayAsYouGo, isPayg, theme, isPaymentOption
}) => {
  const appTheme = getAppTheme();
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo }) && !isPaymentOption && appTheme !== appThemes.dark) return { backgroundColor: '#F7F7F7' };
  return {};
};

const getPaymentMethodBtnClassName = ({ loading }) => {
  if (loading) return classnames(classes.paymentMethodBtn, classes.skeleton);
  return classes.paymentMethodBtn;
};

const onSourceClickHandler = ({
  disableEdit, reauthorizeRequired, expired, errorText, onEditSource, id, options, theme, onMakeDefault,
  managePaymentSource, kind, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
}) =>  {
  if (!disableEdit && (reauthorizeRequired || expired || errorText)
    && errorText !== purchaseErrorText.cannotBeUsedOnThisBrowser) {
    if (errorText === purchaseErrorText.pleaseReEnterTheCardDetailsForSecurityReasons
      && editCardIfSecurityReasons) {
      editCardIfSecurityReasons(id, kind);
      return;
    }
    onEditSource(id);
    return;
  }
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo })) {
    if (isPaymentOption) managePaymentSource(id);
    return;
  }
  if (theme === paymentSourcesTheme.purchase && (options && options.length < 1)) {
    onMakeDefault();
    return;
  }
  managePaymentSource(id);
};

const sourceImgStyle = (changingSource) => (changingSource ? classes.sourceImgChangingSource : classes.sourceImg);

function PaymentMethod({
  isDefault,  reauthorizeRequired, changingSource,
  expired, id, onEditSource, disableEdit, kind, onMakeDefault,
  errorText, context, managePaymentSource, theme, loading, options, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
}) {
  const { t } = useTranslation();
  const { type, last4, paypalBillingAgreementEmail } = context || {};

  const paypalSorce =  (
    <div
      className={ classes.mobileSource }
      style={ {
        ...borderColor({ isDefault, changingSource, theme }),
        ...backgroundColor({
          eligibleToPayAsYouGo, isPayg, theme, isPaymentOption, errorText
        })
      } }
    >
      { renderErrorImg({ errorText }) }
      <div className={ classes.sourceNameContainer }>
        <img src={ payPalImg } alt="" className={ sourceImgStyle(changingSource) } />
        <div className={ classes.sourceShortInfo }>
          Paypal
          { renderAdditionalText({
            errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg
          }) }
        </div>
      </div>
      { renderArrowImg({ theme, changingSource }) }
    </div>
  );

  const creditCardSource = (
    <div
      className={ classes.mobileSource }
      style={ {
        ...borderColor({ isDefault, changingSource, theme }),
        ...backgroundColor({
          eligibleToPayAsYouGo, isPayg, theme, isPaymentOption, errorText
        })
      } }
    >
      { renderErrorImg({ errorText }) }
      <div className={ classes.sourceNameContainer }>
        <CreditCardImg
          className={ sourceImgStyle(changingSource) }
          type={ type }
          large={ theme === paymentSourcesTheme.wallet }
        />
        <div className={ classes.sourceShortInfo }>
          { formatCardName(type) }
          {' * * * '}
          { last4 }
          { renderAdditionalText({
            errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg
          }) }
        </div>
      </div>
      { renderArrowImg({ theme, changingSource }) }
    </div>
  );

  const renderLoadingContent = () => (<div className={ classes.loadingContent } />);

  const renderContent = () => {
    if (loading) return renderLoadingContent();
    switch (kind) {
      case paymentKind.creditCard: {
        return creditCardSource;
      }
      case paymentKind.payPal: {
        return paypalSorce;
      }
      default:
        return null;
    }
  };

  const onSourceClick = () =>  {
    onSourceClickHandler({
      disableEdit, reauthorizeRequired, expired, errorText, onEditSource, id, options, theme, onMakeDefault,
      managePaymentSource, kind, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
    });
  };

  const renderPaymentMethod = () => (
    <div className={ classes[`sourceContainer_${ theme }`] }>
      <button type="button" onClick ={ onSourceClick } className={ getPaymentMethodBtnClassName({ loading }) }>
        { renderContent() }
      </button>
    </div>
  );

  return renderPaymentMethod();
}

PaymentMethod.propTypes = {
  id: PropTypes.number,
  kind: PropTypes.string,
  context: PropTypes.object,
  isDefault: PropTypes.bool,
  expired: PropTypes.bool,
  options: PropTypes.array,
  editable: PropTypes.bool,
  onEditSource: PropTypes.func,
  onMakeDefault: PropTypes.func,
  removeSource: PropTypes.func,
  disableEdit: PropTypes.bool,
  managePaymentSource: PropTypes.func.isRequired,
  clickSource: PropTypes.string,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  loading: PropTypes.bool,
  changingSource: PropTypes.oneOfType(
    [PropTypes.bool, PropTypes.string, PropTypes.number]
  ).isRequired,
  editCardIfSecurityReasons: PropTypes.func,
  isPayg: PropTypes.bool,
  isPaymentOption: PropTypes.bool
};

PaymentMethod.defaultProps = {
  id: null,
  kind: null,
  context: {},
  isDefault: null,
  expired: null,
  options: [],
  editable: true,
  disableEdit: false,
  onEditSource: null,
  onMakeDefault: null,
  removeSource: null,
  clickSource: '',
  theme: paymentSourcesTheme.wallet,
  loading: null,
  changingSource: false,
  editCardIfSecurityReasons: null,
  isPayg: null,
  isPaymentOption: null
};

export default PaymentMethod;
